import React from "react";

const TermsPage = () => {
    return (
        <body>
            <div style={{ padding: "65px", paddingTop: "150px", backgroundColor: "#f9f9f9" }}>
                <h1 className="TC">Terms and Conditions for Payments on Overseas.ai</h1>

                <p>These Payment <strong>Terms and Conditions</strong> (“Terms”) govern the use of the payment services provided on <strong>Overseas.ai</strong> (“Platform,” “we,” “our,” or “us”). By making a payment for job applications or related services, you (“User” or “you”) agree to be bound by these Terms.</p>

                <h2>1. Payment Gateway Services</h2>
                <ul>
                    <li><strong> 1.1 Purpose:</strong> Overseas.ai offers a payment gateway for users to pay fees associated with job
                        applications and other services on the platform.
                    </li>
                    <li><strong> 1.2 Third-Party Provider:</strong> : Payments on Overseas.ai are processed by a third-party payment provider. By making a payment, you agree to comply with any additional terms and conditions
                        of the payment provider.
                    </li>
                </ul>

                <h2>2. Fees and Charges</h2>
                <ul>
                    <li><strong>2.1 Job Application Plan Fees:</strong>Users may purchase a Job application plan, which allows them
                        to apply for a specified number of jobs, as displayed on the payment page. </li>
                    <li><strong>2.2 Service Charges:</strong> Additional service fees may apply based on the services you used set by
                        the payment provider, disclosed during checkout. </li>
                    <li><strong>2.3 Currency:</strong> Payments are processed in INR unless otherwise specified.</li>
                </ul>

                <h2>3. Payment Methods</h2>
                <ul>
                    <li><b>3.1 Accepted Methods:</b> We accept various payment methods, including credit/debit cards,
                        UPI, digital wallets, and other methods offered through our payment provider. </li>
                    <li><b>3.2 Authorization:</b> By initiating a payment, you authorize Overseas.ai or the third-party payment provider to debit your selected payment method.</li>
                </ul>

                <h2>4. Refund Policy</h2>
                <ul>
                    <li><strong>4.1 Application Plan Refund:</strong> If an user purchases a job application plan and is rejected from
                        all jobs he applied to under that plan, he is eligible for a 100% refund within 15 business days
                        of the last rejection notification. All applied jobs must be relevant to the candidate's profile and experience.  </li>
                    <li><strong>4.2 Non-Refundable Services:</strong> Payments for other services on the Overseas.ai platform are
                        final and non-refundable.</li>
                </ul>

                <h2>5. User Responsibilities</h2>
                <ul>
                    <li>
                        <strong>
                            5.1 Accurate Information:
                        </strong>
                        Users must ensure that all payment details are accurate and up-to-date
                    </li>
                    <li><strong>5.2 Compliance:</strong>Users are solely responsible for complying with applicable tax obligations
                        related to payments.
                    </li>
                    <li><b>5.3 Unauthorized Use:</b> You agree to notify us immediately of any unauthorized use of your payment information. </li>
                </ul>

                <h2>6. Dispute Resolution</h2>
                <ul>
                    <li><strong>6.1 Resolution Process:</strong> Any disputes arising from payments must first be submitted to
                        support@overseas.ai. Overseas.ai will attempt to resolve issues in a timely manner.
                    </li>
                    <li><strong>6.2 Legal Jurisdiction:</strong> These Terms are governed by the laws of the Code of Civil Procedure
                        (CPC). Any legal action related to payment disputes will be handled in Calcutta High Court.
                    </li>
                </ul>

                <h2>7. Changes to Terms</h2>
                <p>Overseas.ai reserves the right to update or modify these Payment Terms and Conditions at any
                    time. Changes will be effective immediately upon posting. </p>

                <h2>8. Contact Us</h2>
                <div class="contact">
                    For questions or assistance with payments, please contact us at:
                    <p><strong>Email:</strong> support@overseas.ai</p>
                    <p><strong>Phone:</strong> 9907591478</p>
                    <p><strong>Address:</strong> CA 191, CA Block, Sector 1, Saltlake, Kolkata, West Bengal 700064</p>
                </div>
            </div>
        </body>
    );
};

export default TermsPage;
