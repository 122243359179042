import React from "react";
import "./RefundPolicy.css"; // Import the CSS file
const RefundPolicy = () => {
  return (

    <div style={{ padding: "65px", paddingTop: "150px", backgroundColor: "#f9f9f9" }}>
      <h1>Refund Policy for Overseas.ai</h1>
      <p>
        This Refund Policy governs eligibility and procedures for refunds on <strong>Overseas.ai</strong> (“Platform”).
        By making a payment on Overseas.ai, you agree to the terms outlined below. Please review this policy carefully.
      </p>

      <h2>1. Refund Eligibility</h2>
      <p>
        If you purchase an application plan allowing you to apply for up to 10 jobs and are rejected from all 10 applications,
        you may be eligible for a 100% refund of the application plan payment, provided:
      </p>
      <ul>
        <li>Each job application aligns with your profile and qualifications.</li>
        <li>You satisfy the job criteria set by the employer for each position.</li>
      </ul>

      <h2>2. Non-Refundable Payments</h2>
      <p>
        <strong>2.1 Other Services:</strong>  Payments for additional services available on Overseas.ai, beyond job
        application plans and specific job-related service charges, are final and non-refundable.

      </p>
      <p>
        <strong>2.2 General Job Application Fees:</strong> Fees for submitting individual applications outside an application plan are also non-refundable unless otherwise stated in writing.
      </p>

      <h2>3. Conditions for Refund</h2>
      <p>Only applications meeting both candidate profile relevance and job criteria eligibility qualify for refunds under the application plan refund policy.</p>

      <h2>4. Application Plan Refund Request</h2>
      <p>
        After you receive rejection notifications for all 10 applications under the application plan,
        contact Support at <a href="mailto:support@overseas.ai">support@overseas.ai</a> with your account details and job application reference numbers.
      </p>

      <h2>5. Refund Processing Time</h2>
      <p>
        Refunds for eligible claims will be processed within 15 business days after Overseas.ai confirms eligibility.
        Refunds will be issued to the original payment method.
      </p>

      <h2>6. Disputes and Appeals</h2>
      <p>
        If your refund request is denied and you wish to appeal, you may reach out to
        <a href="mailto:support@overseas.ai"> support@overseas.ai</a> with the details of your case.
        Overseas.ai will review the appeal and respond within 10 business days.
      </p>

      <h2>7. Changes to Refund Policy</h2>
      <p>Overseas.ai reserves the right to amend this Refund Policy at any time. Any changes will be posted on the Platform and take effect immediately upon posting.</p>

      <h2>8. Contact Information</h2>
      <div className="contact-info">
        For further information, please contact:
        <p>Email: <a href="mailto:support@overseas.ai">support@overseas.ai</a></p>
        <p>Phone: +91 9907591478</p>
        <p>Address: CA 191, CA Block, Sector 1, Saltlake, Kolkata, West Bengal 700064</p>
      </div>

      <p>By purchasing services on Overseas.ai, you acknowledge and agree to this Refund Policy.</p>
      <p>Thank you for using our platform.</p>
    </div>
  );
};

export default RefundPolicy;
